import React from "react";
import styles from './view_selector.module.css';

export default function ViewSelector({viewType, setViewType}) {
    return (
        <div className={styles.container}>
            <div className={`${styles.itemView} ${viewType === 'items' ? styles.selected : ''}`} onClick={() => setViewType('items')}>
                <img alt={'item_view'} src={`/icons/item_view${viewType === 'items' ? '' : '_off'}.png`} width={'20px'}/>
            </div>
            <div className={`${styles.listView} ${viewType === 'list' ? styles.selected : ''}`} onClick={() => setViewType('list')}>
                <img alt={'list_view'} src={`/icons/list_view${viewType === 'list' ? '' : '_off'}.png`} width={'20px'}/>
            </div>
        </div>
    )
}