import React, {useState} from "react";
import styles from './sidebar.module.css';
import {faArrowRightFromBracket, faChevronRight, faCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

function Logout() {

    const logout = () => {
        window.location.replace('/login');
        localStorage.clear();
    }

    return (
        <div className={styles.logoutContainer} onClick={() => logout()}>
            <div className={styles.logout}>
                <div className={styles.logoutTip}/>
                <div className={'d-flex align-items-center'} style={{zIndex: 100}}>
                    <FontAwesomeIcon className={'pe-2'} icon={faArrowRightFromBracket}/>
                    <div style={{fontSize: '14px', letterSpacing: '8%', fontWeight: 350}}>Log Out</div>
                </div>
            </div>
        </div>
    )
}

function SidebarItem({item, selected}) {
    const navigate = useNavigate();
    return (
        <div className={`${styles.sidebarItem} ${selected ? styles.sidebarItemSelected : ''}`} onClick={() => {
            navigate(item.route);
        }}>
            {selected && <div className={styles.sidebarItemPill}/>}
            <img alt={item.name} src={item.icon} className={'me-4'} width={'25px'}/>
            <div>{item.name}</div>
        </div>
    )
}

function Settings() {
    return (
        <div className={styles.settings}>
            <FontAwesomeIcon icon={faCog}/>
            <div className={'mx-2'}>Ajustes</div>
            <FontAwesomeIcon icon={faChevronRight}/>
        </div>
    );
}

function Profile({first_name, last_name}) {
    const [showLogout, setShowLogout] = useState(false);
    return (
      <div className={styles.profile} onClick={() => setShowLogout((prev) => !prev)}>
          <div className={styles.profileImage}/>
          <div>
            <div style={{fontWeight: '550', fontSize: '15px'}}>{first_name} {last_name}</div>
              {/*<div style={{color: '#A9AFD0', fontSize: '12px'}}>Mi Perfil</div>*/}
          </div>
          {showLogout && <Logout/>}
      </div>
    );
}

export default function Sidebar({selectedIndex}) {
    const {first_name, last_name} = localStorage;

    const items = [
        {name: 'Dashboard', icon: '/sidebar/dashboard.png', route: '/'},
        {name: "KPDU´s", icon: '/sidebar/kpdus.png', route: '/list'},
        /*{name: "Calidad de Vías", icon: '/sidebar/quality.png', route: '/roads'},
        {name: "Medida de Red", icon: '/sidebar/net.png', route: '/network'},*/
    ]

    return (
        <div className={styles.container}>
            <div className={`${styles.logoContainer}`}>
                <img alt={'stracontech'} src={'/branding/stracontech_logo_final.png'} height={'40px'}/>
            </div>
            <div className={'d-flex justify-content-center w-100'}>
                <div className={styles.sidebarDivider} style={{marginBottom: '40px'}}/>
            </div>
            <div className={styles.menu}>
                {items?.map((item, index) => {
                    return <SidebarItem selected={selectedIndex === index} key={index} item={item}/>
                })}
            </div>
            <div className={styles.sidebarBottom}>
                <div className={styles.sidebarDivider}/>
                {/*<div style={{padding: '16px 0'}}>
                    <Settings/>
                </div>
                <div className={styles.sidebarDivider}/>*/}
                <Profile first_name={first_name} last_name={last_name}/>
            </div>
        </div>
    )
}