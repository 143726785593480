import React, {useEffect, useState} from "react";
import styles from './battery_gauge.module.css';

export default function BatteryGauge({name, unit, value}) {
    const [level, setLevel] = useState(0);
    const calculateLevel = () => {
        let valueCopy = value;

        if (valueCopy <= 48) {
            let section = 48.0 / 3.0;
            if (valueCopy <= section) {
                setLevel(1);
            } else if (valueCopy <= section * 2) {
                setLevel(2);
            } else {
                setLevel(3);
            }
        } else if (valueCopy <= 208) {
            let section = (208.0 - 48.0) / 3.0;
            if (valueCopy <= section + 48.0) {
                setLevel(4);
            } else if (valueCopy <= section * 2 + 48.0) {
                setLevel(5);
            } else {
                setLevel(6);
            }
        } else {
            let section = (480.0 - 208.0) / 3.0;
            if (valueCopy <= section + 208.0) {
                setLevel(7);
            } else if (valueCopy <= section * 2 + 208.0) {
                setLevel(8);
            } else {
                setLevel(9);
            }
        }
    }

    useEffect(() => {
        calculateLevel();
    }, [value]);

    return (
        <div className={styles.container}>
            <div className={styles.batteryContainer}>
                <div className={styles.pill} style={{backgroundColor: level >= 9 ? '#D53C3C' : '#A9A9A9'}}/>
                <div className={styles.pill} style={{backgroundColor: level >= 8 ? '#D53C3C' : '#A9A9A9'}}/>
                <div className={styles.pill} style={{backgroundColor: level >= 7 ? '#D53C3C' : '#A9A9A9'}}/>
                <div className={styles.pill} style={{backgroundColor: level >= 6 ? '#EED057' : '#A9A9A9'}}/>
                <div className={styles.pill} style={{backgroundColor: level >= 5 ? '#EED057' : '#A9A9A9'}}/>
                <div className={styles.pill} style={{backgroundColor: level >= 4 ? '#EED057' : '#A9A9A9'}}/>
                <div className={styles.pill} style={{backgroundColor: level >= 3 ? '#88D36E' : '#A9A9A9'}}/>
                <div className={styles.pill} style={{backgroundColor: level >= 2 ? '#88D36E' : '#A9A9A9'}}/>
                <div className={styles.pill} style={{backgroundColor: level >= 1 ? '#88D36E' : '#A9A9A9'}}/>
            </div>
            <div className={styles.text}>
                <div className={'d-flex align-items-baseline'}>
                    <div style={{fontSize: '32px', minWidth: '63px'}}>{Math.abs(value ? value : 0).toFixed(1)}</div>
                    <div>{unit}</div>
                </div>
                <div style={{color: '#C1C1C1', fontSize: '11px'}}>{name}</div>
            </div>
        </div>
    )
}