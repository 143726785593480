import React, {useEffect, useState} from "react";
import styles from './dashboard.module.css';
import Sidebar from "../../components/sidebar/sidebar";
import KpduDashboardBox from "./components/kpdu_box/kpdu_dashboard_box";
import {openModal} from "../../components/ajonjolib/toasts/toast/toast";
import ConfigurationModal from "./modals/configuration/configuration_modal";
import axiosInstance from "../../AxiosInstance";

function DashboardHeader({data}) {
    const {first_name} = localStorage;
    return (
        <div className={styles.header}>
            <div>Bienvenido {first_name}!</div>
            <div className={'d-flex'}>
                <img alt={'stracon_tracks'} src={'/branding/stracon_tracks_fade.png'} height={'113px'} style={{marginRight: '-1px'}}/>
                <div className={styles.configureButton} onClick={() => {
                    openModal("configuration_modal", data);
                }}>
                    <img alt={'configure'} src={'/icons/config_button.png'} width={'27px'} height={'27px'}/>
                    <div className={'pt-2'}>Configurar<br/>Dashboard</div>
                </div>
            </div>
        </div>
    )
}

export default function Dashboard() {
    const [data, setData] = useState([]);
    const [kpdus, setKpdus] = useState([]);

    useEffect(() => {
        axiosInstance.get('kpdu').then((response) => {
            if(response?.status !== 200) {
                return;
            }
            setKpdus(response.data);
        });

        axiosInstance.get('dashboard/detailed_list').then((response) => {
            if(response?.status !== 200) {
                return;
            }
            setData(response.data);
        });
    }, []);

    return (
        <div className={styles.container}>
            <Sidebar selectedIndex={0}/>

            <ConfigurationModal/>


            <div style={{
                marginLeft: '300px',
                marginRight: '20px',
                paddingTop: '80px'
            }}>
                <DashboardHeader data={kpdus}/>
                <div className={'d-flex'}>
                    {data?.map((entry) => {
                        return <KpduDashboardBox key={entry.id} data={entry}/>
                    })}
                </div>
            </div>
        </div>
    );
}