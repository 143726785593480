// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox_checkbox__ldAaW {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
}

.checkbox_checkboxWrapper__qqX4N {
    display: flex;
    align-items: center;
}

.checkbox_checkboxInput__DmmOM {
    display: flex;
    align-items: center;
}

.checkbox_customCheckbox__NIA64 {
    width: 20px;
    height: 20px;
    border: 2px solid #626465;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox_checkboxIcon__j51DL {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.checkbox_customCheckbox__NIA64.checkbox_checked__7bmMd {
    border: 2px solid #1976D2;
    background-color: #1976D2;
}

.checkbox_customCheckbox__NIA64.checkbox_checked__7bmMd .checkbox_checkboxIcon__j51DL {
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/inputs/checkbox/checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;AACd","sourcesContent":[".checkbox {\n    display: flex;\n    align-items: center;\n    user-select: none;\n}\n\n.checkboxWrapper {\n    display: flex;\n    align-items: center;\n}\n\n.checkboxInput {\n    display: flex;\n    align-items: center;\n}\n\n.customCheckbox {\n    width: 20px;\n    height: 20px;\n    border: 2px solid #626465;\n    border-radius: 4px;\n    background-color: #fff;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.checkboxIcon {\n    opacity: 0;\n    transition: opacity 0.3s ease-in-out;\n}\n\n.customCheckbox.checked {\n    border: 2px solid #1976D2;\n    background-color: #1976D2;\n}\n\n.customCheckbox.checked .checkboxIcon {\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `checkbox_checkbox__ldAaW`,
	"checkboxWrapper": `checkbox_checkboxWrapper__qqX4N`,
	"checkboxInput": `checkbox_checkboxInput__DmmOM`,
	"customCheckbox": `checkbox_customCheckbox__NIA64`,
	"checkboxIcon": `checkbox_checkboxIcon__j51DL`,
	"checked": `checkbox_checked__7bmMd`
};
export default ___CSS_LOADER_EXPORT___;
