import React, {useEffect, useState} from "react";
import styles from './table.module.css';
import axiosInstance from "../../../AxiosInstance";
import {useNavigate} from "react-router-dom";

function getValueFromString(object, accessString) {
    let accessParts = accessString.split(".");
    let value = object;

    for (let i = 0; i < accessParts?.length; i++) {
        if (!value) {
            return "";
        }
        let accessPart = accessParts[i];
        value = value[accessPart];
    }

    return value;
}

export default function Table({cols, withIndex, model, create}) {
    const [query, setQuery] = useState('');
    const [rows, setRows] = useState([]);
    const [timer, setTimer] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            const url = `${model}/?name=${query}`;
            axiosInstance.get('kpdu/').then((response) => {
                if(response?.status !== 200) {
                    return;
                }
                setRows(response.data);
            });
        }, 400);

        setTimer(newTimer);
    }, [model, query]);

    return (
        <div className={styles.container}>
            <div className={styles.head}>
                <div className={styles.searchbar}>
                    <input
                        placeholder={'Buscar'}
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                </div>
                {create && <div className={styles.addContainer} onClick={create}>+ Nuevo</div>}
            </div>

            <div style={{
                height: 'calc(100% - 100px)', overflowY: 'scroll', position: 'relative'
            }}>
                <div className={styles.body}>
                    <table className={`${styles.table} table`}>
                        <thead>
                        <tr>
                            {withIndex && <th style={{paddingLeft: '20px', color: '#BBBEC2'}}>N°</th>}
                            {cols?.map((col, index) => (
                                <th key={index}>
                                    <div className={'d-flex justify-content-between'}>
                                        <div className={styles.fakeMargin}/>
                                        <div style={{textAlign: 'left', width: '100%', whiteSpace: 'nowrap', color: '#BBBEC2'}}>{col.title}</div>
                                        <div className={styles.fakeMargin}/>
                                    </div>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {rows?.map((row, rowIndex) => (
                            <tr key={rowIndex} onClick={() => {
                                navigate(`/detail/${row.id}/`);
                            }}>
                                {withIndex && <td style={{color: '#F32735', fontWeight: '600', paddingLeft: '20px'}}>
                                    <div style={{height: '100%'}} className={'d-flex align-items-center'}>{rowIndex + 1}</div>
                                </td>}
                                {cols?.map((col, colIndex) => (
                                    <td key={colIndex}>
                                        <div className={styles.customtd}>
                                            <div style={{width: '100%', color: '#1A2A4D'}}>
                                            {col.image ?
                                                <img src={getValueFromString(row,col.image.url)} alt={col.title} width={"50px"} height={"50px"} style={{borderRadius: "5px", objectFit: "contain", backgroundColor: "#F7F8F9"}}/>
                                                :
                                                <>
                                                    { col.code ? <>{col.code(row, getValueFromString(row, col.key))}</>
                                                        :
                                                        <div>{getValueFromString(row, col.key)}</div>
                                                    }
                                                </>
                                            }
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}