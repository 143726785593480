// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view_selector_container__ak8zg {
    position: fixed;
    z-index: 280;
    left: var(--sidebar-width);
    top: 86px;
    -webkit-user-select: none;
            user-select: none;
}

.view_selector_itemView__rmXU4,
.view_selector_listView__KSVc2 {
    height: 87px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    background-color: #D9D9D9;
}

.view_selector_itemView__rmXU4 {
    border-top-right-radius: 16px;
}

.view_selector_listView__KSVc2 {
    border-bottom-right-radius: 16px;
}

.view_selector_selected__-AkUE {
    background-color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/screens/kpdus/components/view_selector/view_selector.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,0BAA0B;IAC1B,SAAS;IACT,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;;IAEI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".container {\n    position: fixed;\n    z-index: 280;\n    left: var(--sidebar-width);\n    top: 86px;\n    user-select: none;\n}\n\n.itemView,\n.listView {\n    height: 87px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    width: 50px;\n    background-color: #D9D9D9;\n}\n\n.itemView {\n    border-top-right-radius: 16px;\n}\n\n.listView {\n    border-bottom-right-radius: 16px;\n}\n\n.selected {\n    background-color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `view_selector_container__ak8zg`,
	"itemView": `view_selector_itemView__rmXU4`,
	"listView": `view_selector_listView__KSVc2`,
	"selected": `view_selector_selected__-AkUE`
};
export default ___CSS_LOADER_EXPORT___;
