import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import RouteContainer from "./RouteContainer";
import ToastContainer from "./components/ajonjolib/toasts/toast/toast_container";

function App() {
  return (
      <div className="App">
          <Router>
              <RouteContainer/>
          </Router>
          <ToastContainer/>
      </div>
  );
}

export default App;
