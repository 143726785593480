import React, { useEffect, useState } from "react";
import styles from "./alert_section.module.css";
import {
  alertToIcon,
  formatTimestamp,
  typeToName,
  typeToUnit,
} from "../../../../../utils/utils";
import axiosInstance from "../../../../../AxiosInstance";

function Alert({ data, lastPort }) {
  if (data.alert_type === 5) {
    return (
      <div className={styles.alert}>
        <img
          src={alertToIcon(data.alert_type)}
          alt={"emergency_stop"}
          width={"45px"}
          height={"45px"}
        />
        <div className={styles.alertTitle}>
          <div>{typeToName(data.alert_type, data.alert_subtype)}</div>
          <div>-</div>
        </div>
        <div>
          <div
            style={{
              width: "4px",
              height: "4px",
              backgroundColor: "#000",
              borderRadius: "50%",
            }}
          />
          <div style={{ height: "20px" }} />
        </div>
        <div>
          <div className={styles.alertDay}>
            {formatTimestamp(data.created_at)}
          </div>
          <div style={{ height: "20px" }} />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.alert}>
      <img
        src={alertToIcon(data.alert_type)}
        alt={"voltage_alert"}
        width={"45px"}
        height={"45px"}
      />
      <div className={styles.alertTitle}>
        <div>{typeToName(data.alert_type, data.alert_subtype)}</div>
        {data?.port_alert === 0 ? (
          <div>EN LA ENTRADA</div>
        ) : (
          <div>EN PUERTO {data.port_alert}</div>
        )}
      </div>
      <div>
        <div
          style={{
            width: "4px",
            height: "4px",
            backgroundColor: "#000",
            borderRadius: "50%",
          }}
        />
        <div style={{ height: "20px" }} />
      </div>
      <div>
        <div className={styles.alertDay}>
          {formatTimestamp(data.created_at)}
        </div>
        <div style={{ height: "20px" }} />
      </div>
      <div className={styles.alertValue}>
        <div>{parseFloat(data.alert_value).toFixed(0)}</div>
        <div>{typeToUnit(data.alert_type)}</div>
      </div>
    </div>
  );
}

function AlertDate({ date }) {
  return (
    <div className={styles.alertDate}>
      <div>{date}</div>
      <div>{date}</div>
    </div>
  );
}

export default function AlertSection({ alerts, kpduData }) {
  const [lastPort, setLastPort] = useState(null);

  useEffect(() => {
    setLastPort(kpduData?.model.port_count);
  }, [kpduData]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={"d-flex align-items-center"}>
          <img src={"/icons/alerts/alert.png"} alt={"alert"} width={"20px"} />
          <div className={"ps-2"}>Alertas</div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.section}>
          <div className={styles.day}>
            <AlertDate />
          </div>
          <div className={styles.alerts}>
            {alerts.map((alert, index) => (
              <Alert lastPort={lastPort} key={index} data={alert} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
