import React from "react";
import styles from "../../../detail.module.css";
import LineGraph from "../../line_graph/line_graph";

export default function UsageSection() {
    return (
        <div>
            <LineGraph unit={'KW/h'} min={0} max={10} color={'#4880b8'} secondaryColor={'#4880b8'} entries={[]} valueName={`avg_power`} header={[
                <div className={'d-flex justify-content-between align-items-center px-3 py-3'}>
                    <div className={'d-flex align-items-center'}>
                        <img alt={'thunder'} src={'/icons/purple_thunder.png'} width={'13px'} height={'100%'} className={'me-2'}/>
                        <div className={'d-flex align-items-center'} style={{color: '#19294999', fontSize: '16px', fontWeight: 600, whiteSpace: 'nowrap'}}>
                            <div className={'pe-2'}>Consumo Electrico General</div>
                            <div style={{
                                color: '#F32735'
                            }}>OUT</div>
                        </div>
                    </div>
                    <div className={'d-flex'}>
                        <div className={styles.frequency}>Año <div>2024</div></div>
                        <div className={styles.frequency}>Mes <div>Febrero</div></div>
                        <div className={styles.frequency}>Semana</div>
                    </div>
                </div>
            ]}/>
        </div>
    )
}