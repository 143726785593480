// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio_container__zYGr1 {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
    flex-direction: column;
}

.radio_optionsContainer__lk7UO {

}`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/inputs/radio/radio.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;;AAEA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    user-select: none;\n    flex-direction: column;\n}\n\n.optionsContainer {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `radio_container__zYGr1`,
	"optionsContainer": `radio_optionsContainer__lk7UO`
};
export default ___CSS_LOADER_EXPORT___;
