import React, { useEffect, useState } from "react";
import styles from "./detail.module.css";
import Sidebar from "../../components/sidebar/sidebar";
import Output from "../../components/output/output";
import LineGraph from "./components/line_graph/line_graph";
import { useParams } from "react-router-dom";
import axiosInstance from "../../AxiosInstance";
import OutputGraphicGroup from "./components/output_graphic_group/output_graphic_group";
import DetailsHeader from "./components/details_header/details_header";
import MainSelect from "./components/main_select/main_select";
import TemperatureSection from "./components/sections/temperature_section/temperature_section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import AlertSection from "./components/sections/alert_section/alert_section";
import UsageSection from "./components/sections/usage_section/usage_section";
import OutputSettingsModal from "./modals/output_settings";
import OutputStatesModal from "./modals/output_states";
import ResetAllModal from "./modals/reset_all";

export default function KPDUDetail() {
  const { kpdu_id } = useParams();
  const [outputs, setOutputs] = useState([]);
  const [temperatures, setTemperatures] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [selectedOutput, setSelectedOutput] = useState(-1);
  const [selectedSection, setSelectedSection] = useState(1);
  const [kpduData, setKpduData] = useState({});
  const [recentEmergency, setRecentEmergency] = useState(false);
  const [locked, setLocked] = useState(false);
  const [alertLock, setAlertLock] = useState(false);
  const [temperatureLock, setTemperatureLock] = useState(false);

  useEffect(() => {
    if (locked) return;
    setLocked(true);
    axiosInstance
      .get(`kpdu/${kpdu_id}/`)
      .then((response) => {
        if (response?.status !== 200) {
          return;
        }
        setKpduData(response.data);
        let relays = response.data.relays;
        relays["status"] = relays.voltages.map((voltage) => voltage > 1);
        relays["ignition_status"] = response.data.ignition_status;
        if (response.data.latest) {
          setOutputs(relays);
        }
      })
      .finally(() => {
        setLocked(false);
      });
  }, [locked]);

  useEffect(() => {
    if (temperatureLock || selectedSection !== 3) return;
    setTemperatureLock(true);
    axiosInstance
      .get(`kpdu/${kpdu_id}/temperature/`)
      .then((response) => {
        if (response?.status !== 200) {
          return;
        }
        setTemperatures(response.data);
      })
      .finally(() => {
        setTemperatureLock(false);
      });
  }, [temperatureLock, selectedSection]);

  useEffect(() => {
    if (alertLock || selectedSection !== 2) return;
    setAlertLock(true);
    console.log(alertLock);
    axiosInstance
      .get(`/alert/?kpdu_id=${kpdu_id}`)
      .then((response) => {
        if (response?.status !== 200) {
          return;
        }
        setAlerts(response.data);
        let found = false;
        response.data.forEach((alert) => {
          if (alert.alert_type === 5) {
            // if alert.created_at is less than 10 seconds ago
            let now = new Date();
            let alertDate = new Date(alert.created_at);
            let diff = (now - alertDate) / 1000;
            if (diff < 10) {
              found = true;
              setRecentEmergency(true);
            }
          }
        });
        if (!found) {
          setRecentEmergency(false);
        }
      })
      .finally(() => {
        setAlertLock(false);
      });
  }, [alertLock, selectedSection]);

  const getSection = () => {
    if (selectedSection === 1) {
      return <UsageSection selectedSection={selectedSection} />;
    } else if (selectedSection === 2) {
      return (
        <AlertSection
          kpduData={kpduData}
          alerts={alerts}
          kpdu_id={kpdu_id}
          setRecentEmergency={setRecentEmergency}
          selectedSection={selectedSection}
        />
      );
    } else if (selectedSection === 3) {
      return (
        <TemperatureSection
          kpduData={kpduData}
          temperatures={temperatures}
          selectedSection={selectedSection}
        />
      );
    }
  };

  return (
    <div>
      <Sidebar selectedIndex={1} />
      <OutputStatesModal />
      <OutputSettingsModal />
      <ResetAllModal />
      <DetailsHeader kpdu_id={kpdu_id} kpduData={kpduData} />
      <MainSelect
        recentEmergency={recentEmergency}
        data={outputs}
        kpduData={kpduData}
        setSelectedSection={setSelectedSection}
        selectedSection={selectedSection}
        setSelectedOutput={setSelectedOutput}
      />

      <div className={styles.container}>
        <div className={styles.outputContainer}>
          {Array.from(
            { length: kpduData?.model?.port_count },
            (_, i) => i + 1
          ).map((i) => (
            <Output
              onClick={() => {
                setSelectedOutput(i);
                setSelectedSection(-1);
              }}
              data={outputs}
              key={i}
              number={i}
              className={"mb-2"}
              kpdu_id={kpdu_id}
              kpduData={kpduData}
            />
          ))}
        </div>
        <div className={styles.detailContainer}>
          <div className={"d-flex justify-content-start pb-3"}>
            <FontAwesomeIcon
              icon={faX}
              color={"#6A86C8"}
              size={"lg"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedSection(1);
                setSelectedOutput(-1);
              }}
            />
          </div>
          {selectedOutput !== -1 || selectedSection === 1 ? (
            <OutputGraphicGroup
              selectedOutput={selectedOutput}
              kpdu_id={kpdu_id}
            />
          ) : (
            getSection()
          )}
        </div>
      </div>
    </div>
  );
}
