import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import get from "lodash/get";

import styles from "./line_graph.module.css";
import CustomToolTip from "./custom_tooltip/custom_tooltip";

function CustomYAxis({ x, y, payload }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#000" fontSize={"12px"}>
        {parseInt(payload.value)}
      </text>
    </g>
  );
}
export default function LineGraph({
  header,
  color,
  secondaryColor,
  entries,
  valueName,
  min,
  max,
  unit,
}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const finalEntries = entries?.map((entry, index) => {
      const value = get(entry, valueName);
      return {
        key: index,
        value: value,
      };
    });
    setData(finalEntries);
  }, [entries]);

  return (
    <div className={styles.container}>
      {header && header}
      <div style={{ width: "100%", height: "200px" }}>
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <AreaChart data={data} width={"400"} height={"100%"}>
            {/*<defs>
                    <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={color} stopOpacity={1}/>
                        <stop offset="95%" stopColor={color} stopOpacity={0}/>
                    </linearGradient>
                </defs>*/}
            <CartesianGrid />

            <XAxis
              dataKey="key"
              padding={{ left: 10, right: 10 }}
              stroke={"#d9d9d9"}
              strokeWidth={2}
              tick={{ fill: "#999", fontSize: "14px" }}
            />
            <YAxis
              dataKey="value"
              domain={[min, max]}
              padding={{ bottom: 15, top: 30 }}
              stroke={"#d9d9d9"}
              strokeWidth={2}
              tick={<CustomYAxis />}
              label={{
                value: unit,
                position: "insideTop",
                fill: color,
                fontSize: "12px",
                fontWeight: "600",
              }}
            />
            <Tooltip
              content={
                <CustomToolTip
                  color={secondaryColor}
                  chartData={data}
                  unit={unit}
                />
              }
            />
            <Area
              fill="url(#colorValue)"
              type="monotone"
              dataKey="value"
              stroke={color}
              strokeWidth={3}
              unit={unit}
              dot={{ r: 0, fill: "#FFF" }}
              activeDot={{ r: 0 }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
