import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";

import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import {
  eventBus,
  toast,
  ToastTypes,
} from "../../../../components/ajonjolib/toasts/toast/toast";
import { HorizontalRadioButtons } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import styles from "./index.module.css";
import ModalFooter from "../../../../components/ajonjolib/modal/modal_footer/modal_footer";
import axiosInstance from "../../../../AxiosInstance";

function Custom({ data, form, setForm }) {
  return (
    <div className={"d-flex justify-content-center align-items-center"}>
      <div style={{ position: "relative", width: "440px" }}>
        <img
          src={`/kpdus/${data.model.image_name}/kpdu_ports.png`}
          alt={"kpdu"}
          width={"440px"}
          height={"auto"}
        />
        {Array.from(
          { length: data?.model.port_count },
          (_, index) => index + 1
        ).map((port) => {
          return (
            <div
              key={port?.id}
              className={`${styles.hoverablePort} ${
                form["on_states"][port - 1] ? styles.active : ""
              }`}
              style={{
                cursor: "pointer",
                top: `${data.model["port_y_values"][port - 1]}%`,
                left: `${data.model["port_x_values"][port - 1]}%`,
              }}
              onClick={() => {
                setForm({
                  ...form,
                  on_states: form.on_states.map((state, index) =>
                    index === port - 1 ? !state : state
                  ),
                });
              }}
            >
              {port}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function LastState({ data, form, setForm }) {
  return (
    <div className={"d-flex justify-content-center align-items-center"}>
      <div style={{ position: "relative", width: "440px" }}>
        <img
          src={`/kpdus/${data?.model?.image_name}/kpdu_ports.png`}
          alt={"kpdu"}
          width={"440px"}
          height={"auto"}
        />
        {Array.from(
          { length: data?.model.port_count },
          (_, index) => index + 1
        ).map((port) => {
          return (
            <div
              key={port?.id}
              className={`${styles.port} ${port.state ? styles.active : ""}`}
              style={{
                top: `${data.model["port_y_values"][port - 1]}%`,
                left: `${data.model["port_x_values"][port - 1]}%`,
              }}
            >
              {port}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function StateSection({ data, form, setForm }) {
  const [state, setState] = useState(data?.state);

  useEffect(() => {
    setForm({
      ...form,
      state: state,
    });
  }, [state]);

  return (
    <div>
      <div
        className={"d-flex justify-content-start align-items-start my-4 ps-5"}
      >
        <HorizontalRadioButtons
          value={state}
          onChange={(value) => {
            setState(value);
          }}
        />
      </div>

      {state === 1 && <Custom data={data} form={form} setForm={setForm} />}
      {state === 2 && <LastState data={data} form={form} setForm={setForm} />}
      {state === 3 && (
        <>
          Prendido amigable. Puedes setear el orden de prendido y el delay entre
          puertos en la pestaña de Reset.
        </>
      )}
    </div>
  );
}

function AlwaysSection({ data, form, setForm }) {
  return (
    <div className={"d-flex justify-content-center align-items-center mt-4"}>
      <div style={{ position: "relative", width: "440px" }}>
        <img
          src={`/kpdus/${data.model.image_name}/kpdu_ports.png`}
          alt={"kpdu"}
          width={"440px"}
          height={"auto"}
        />
        {Array.from(
          { length: data?.model.port_count },
          (_, index) => index + 1
        ).map((port) => {
          return (
            <div
              key={port?.id}
              className={`${styles.hoverablePort} ${
                form["always_on"][port - 1] ? styles.active : ""
              }`}
              style={{
                cursor: "pointer",
                top: `${data.model["port_y_values"][port - 1]}%`,
                left: `${data.model["port_x_values"][port - 1]}%`,
              }}
              onClick={() => {
                setForm({
                  ...form,
                  always_on: form.always_on.map((state, index) =>
                    index === port - 1 ? !state : state
                  ),
                });
              }}
            >
              {port}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function ResetModal({ data, form, setForm, setSaveIsDisabled }) {
  const [duplicatePorts, setDuplicatePorts] = useState([]);

  const findDuplicates = (arr) => {
    const seen = new Set();
    const duplicates = new Set();

    arr.forEach((item, index) => {
      if (seen.has(item)) {
        duplicates.add(item);
      } else {
        seen.add(item);
      }
    });

    return [...duplicates];
  };

  useEffect(() => {
    const duplicates = findDuplicates(form["ports_reset_order"]);
    const isDisabled = duplicates.length > 0;
    setDuplicatePorts(duplicates);
    console.log("duplicatePorts", duplicates);
    console.log("duplicatePorts condition", duplicates.length > 0);
    console.log("isDisabled", isDisabled);
    setSaveIsDisabled(isDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const clockSvg = (
    <svg
      className="w-6 h-6 text-gray-800 dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#284480"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );

  const handleTimeInputChange = (fieldName, port, value) => {
    if (value === "") {
      setForm({
        ...form,
        [fieldName]: form[fieldName].map((val, index) =>
          index === port - 1 ? "" : val
        ),
      });
    } else {
      // Validar que el valor no contenga decimales
      if (/^\d+$/.test(value)) {
        const numValue = Math.max(0, Number(value));
        setForm({
          ...form,
          [fieldName]: form[fieldName].map((val, index) =>
            index === port - 1 ? numValue : val
          ),
        });
      }
    }
  };

  const handlePortOrderChange = (fieldName, port, value, portCount) => {
    if (value === "") {
      setForm({
        ...form,
        [fieldName]: form[fieldName].map((val, index) =>
          index === port - 1 ? "" : val
        ),
      });
    } else {
      // Validar que el valor no contenga decimales
      if (/^\d+$/.test(value)) {
        const numValue = Math.max(1, Math.min(Number(value), portCount));
        setForm({
          ...form,
          [fieldName]: form[fieldName].map((val, index) =>
            index === port - 1 ? numValue : val
          ),
        });
      }
    }
  };

  const handleBlur = (fieldName, port, baseValue = 0) => {
    if (form[fieldName][port - 1] === "") {
      setForm({
        ...form,
        [fieldName]: form[fieldName].map((val, index) =>
          index === port - 1 ? baseValue : val
        ),
      });
    }
  };

  return (
    <div className={"mt-4"}>
      <p>Indica el orden de reset de puertos.</p>
      <Table>
        <thead>
          <tr>
            <th>Puerto</th>
            <th>Orden</th>
            <th>Tiempo entre puertos (s)</th>
            <th>Reset time (s)</th>
          </tr>
        </thead>
        <tbody>
          {Array.from(
            { length: data?.model.port_count },
            (_, index) => index + 1
          ).map((port) => {
            return (
              <tr key={port} className="align-middle">
                <td className={styles.portLabel}>OUT {port}</td>
                <td>
                  <input
                    className={`${styles["custom-input"]} ${
                      duplicatePorts.includes(
                        form["ports_reset_order"][port - 1]
                      )
                        ? styles["error-input"]
                        : ""
                    }`}
                    type="number"
                    step="1"
                    value={form["ports_reset_order"][port - 1]}
                    onChange={(e) =>
                      handlePortOrderChange(
                        "ports_reset_order",
                        port,
                        e.target.value,
                        data?.model.port_count
                      )
                    }
                    onBlur={() => handleBlur("ports_reset_order", port, port)}
                  />
                </td>
                <td>
                  <div className={styles["input-container"]}>
                    {clockSvg}
                    <input
                      className={styles["custom-input"]}
                      type="number"
                      value={form["delay_between_devices"][port - 1]}
                      onChange={(event) =>
                        handleTimeInputChange(
                          "delay_between_devices",
                          port,
                          event.target.value
                        )
                      }
                      onBlur={() => handleBlur("delay_between_devices", port)}
                    />
                  </div>
                </td>
                <td>
                  <div className={styles["input-container"]}>
                    {clockSvg}
                    <input
                      className={styles["custom-input"]}
                      type="number"
                      value={form["ports_reset_time"][port - 1]}
                      onChange={(event) =>
                        handleTimeInputChange(
                          "ports_reset_time",
                          port,
                          event.target.value
                        )
                      }
                      onBlur={() => handleBlur("ports_reset_time", port)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {duplicatePorts.length > 0 && (
        <div className={styles["error-message"]}>Hay puertos repetidos</div>
      )}
    </div>
  );
}

export default function OutputStatesModal() {
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState({});

  const [section, setSection] = useState("state");
  const [submitted, setSubmitted] = useState(false);
  const [saveIsDisabled, setSaveIsDisabled] = useState(false);

  useEffect(() => {
    eventBus.subscribe("output_states_open", (rec_data) => {
      setIsOpen(true);
      setData(rec_data);
      setForm(rec_data);
      setSubmitted(false);
      setSaveIsDisabled(false);
    });
    eventBus.subscribe("output_states_close", (data) => {
      setIsOpen(false);
    });
  }, []);

  const submit = () => {
    setSubmitted(true);
    axiosInstance
      .put(`/kpdu/${data.id}/ign_configuration/`, form)
      .then((response) => {
        if (response?.status !== 200 || !response?.status) {
          toast("Error al guardar los cambios", ToastTypes.ERROR);
        }
      })
      .catch((error) => {
        toast("Error al guardar los cambios", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [submitted]);

  return (
    <div>
      {isOpen && (
        <Modal close={() => setIsOpen(false)}>
          {!submitted ? (
            <>
              <div
                className={
                  "d-flex justify-content-between mt-4 align-items-start"
                }
              >
                <div className={"d-flex"}>
                  <div
                    className={
                      "d-flex justify-content-start flex-column align-items-start ps-3"
                    }
                  >
                    <div
                      style={{
                        fontSize: "26px",
                        color: "#000000",
                        fontWeight: "600",
                      }}
                    >
                      General
                    </div>
                    <div
                      style={{
                        color: "#284480",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Ajuste
                    </div>
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faX}
                  color={"#6A86C8"}
                  size={"lg"}
                  className={"pe-3"}
                  role={"button"}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />
              </div>

              <div className={styles.header}>
                <div
                  className={`${styles.section} ${
                    section === "state" && styles.activeSection
                  }`}
                  onClick={() => setSection("state")}
                >
                  <svg
                    width="9"
                    height="19"
                    viewBox="0 0 9 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.556 19L2.38128 13.7473L3.32601 11.187H0.299336C0.187632 11.1891 0.0854376 11.1321 0.0331495 11.0409C-0.0179491 10.9487 -0.00962897 10.8389 0.0557278 10.7555L8.44044 0L6.61516 5.25266L5.67399 7.81415H8.70066C8.81237 7.81195 8.91456 7.86904 8.96685 7.96017C9.01795 8.05239 9.00963 8.16219 8.94427 8.24563L0.556 19Z"
                      fill="#284480"
                    />
                  </svg>
                  <div className={"ps-2"}>State</div>
                </div>
                <div
                  className={`${styles.section} ${
                    section === "always" && styles.activeSection
                  }`}
                  onClick={() => setSection("always")}
                >
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.341 2.32386C10.1053 2.20597 9.83578 2.18912 9.58315 2.27333C9.33052 2.35755 9.12841 2.54281 9.01052 2.7786C8.77473 3.26702 8.97684 3.87333 9.4821 4.10912C11.2674 4.96807 12.4126 6.80386 12.4126 8.79122C12.4126 11.6544 10.0716 13.9954 7.20842 13.9954C4.34526 13.9954 2.00421 11.6544 2.00421 8.79122C2.00421 6.80386 3.14947 4.96807 4.93473 4.10912C5.44 3.87333 5.6421 3.26702 5.40631 2.7786C5.28842 2.54281 5.08631 2.35755 4.83368 2.27333C4.58105 2.18912 4.31158 2.20597 4.07579 2.32386C1.6 3.50281 0 6.04596 0 8.79122C0 12.766 3.23368 15.9996 7.20842 15.9996C11.1831 15.9996 14.4168 12.766 14.4168 8.79122C14.4168 6.04596 12.8168 3.50281 10.341 2.32386Z"
                      fill="#6A86C8"
                    />
                    <path
                      d="M7.20853 5.81052C7.76432 5.81052 8.20221 5.35579 8.20221 4.81684V0.993683C8.20221 0.437894 7.74747 0 7.20853 0C6.65274 0 6.21484 0.454736 6.21484 0.993683V4.8C6.21484 5.35579 6.65274 5.81052 7.20853 5.81052Z"
                      fill="#6A86C8"
                    />
                  </svg>
                  <div className={"ps-2"}>Always ON Plug</div>
                </div>
                <div
                  className={`${styles.section} ${
                    section === "reset" && styles.activeSection
                  }`}
                  onClick={() => setSection("reset")}
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>

                  <div className={"ps-2"}>Reset</div>
                </div>
              </div>

              {section === "state" && (
                <StateSection data={data} form={form} setForm={setForm} />
              )}
              {section === "always" && (
                <AlwaysSection data={data} form={form} setForm={setForm} />
              )}
              {section === "reset" && (
                <ResetModal
                  data={data}
                  form={form}
                  setForm={setForm}
                  setSaveIsDisabled={setSaveIsDisabled}
                />
              )}

              <ModalFooter submit={submit} buttonDisabled={saveIsDisabled} />
            </>
          ) : (
            <>
              <div className={"mt-5"} />
              <img
                src={"/icons/ok.png"}
                alt={"ok"}
                width={"107px"}
                height={"107px"}
              />
              <div
                style={{
                  color: "#15284B",
                  fontSize: "32px",
                  fontWeight: "600",
                }}
              >
                Guardado
              </div>
              <div
                style={{
                  color: "#15284B",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                Configuración correctamente ingresada.
              </div>
              <ModalFooter
                text={"Ok"}
                submit={() => {
                  setSubmitted(false);
                  setIsOpen(false);
                }}
              />
            </>
          )}

          <div className={"mb-4"} />
        </Modal>
      )}
    </div>
  );
}
