// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.battery_gauge_container__p5WXk {
    display: flex;
    justify-content: left;
    height: 100%;
    align-items: center;
    padding: 0 30px;
}

.battery_gauge_batteryContainer__Ke\\+Jv {
    height: 97px;
    width: 37px;
    background-color: #F2F2F2;
    border-radius: 6px;
    margin-right: 13px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.battery_gauge_pill__tLkvY {
    height: 8px;
    width: 29px;
    border-radius: 6px;
    background-color: #5FC43B;
    transition: background-color 0.3s ease;
}

.battery_gauge_text__6ZxeW {
    font-weight: 600;
    font-family: Poppins, sans-serif;
    color: #192949;
}`, "",{"version":3,"sources":["webpack://./src/components/battery_gauge/battery_gauge.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,sCAAsC;AAC1C;;AAEA;IACI,gBAAgB;IAChB,gCAAgC;IAChC,cAAc;AAClB","sourcesContent":[".container {\n    display: flex;\n    justify-content: left;\n    height: 100%;\n    align-items: center;\n    padding: 0 30px;\n}\n\n.batteryContainer {\n    height: 97px;\n    width: 37px;\n    background-color: #F2F2F2;\n    border-radius: 6px;\n    margin-right: 13px;\n    padding: 4px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.pill {\n    height: 8px;\n    width: 29px;\n    border-radius: 6px;\n    background-color: #5FC43B;\n    transition: background-color 0.3s ease;\n}\n\n.text {\n    font-weight: 600;\n    font-family: Poppins, sans-serif;\n    color: #192949;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `battery_gauge_container__p5WXk`,
	"batteryContainer": `battery_gauge_batteryContainer__Ke+Jv`,
	"pill": `battery_gauge_pill__tLkvY`,
	"text": `battery_gauge_text__6ZxeW`
};
export default ___CSS_LOADER_EXPORT___;
