// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_container__8Kvpf {
    font-family: Poppins, sans-serif;
}

.dashboard_header__DEVuQ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 113px;
    min-height: 113px;
    background-color: #F8F8F8;
    border-radius: 15px;
}

.dashboard_header__DEVuQ>div:first-of-type {
    color: #192949;
    font-size: 40px;
    font-weight: 500;
    padding-left: 23px;
}

.dashboard_configureButton__f7D-g {
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    background-color: #F32735;
    border-radius: 0 15px 15px 0;
    height: 113px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 133px;
    min-width: 133px;
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/screens/dashboard/dashboard.module.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".container {\n    font-family: Poppins, sans-serif;\n}\n\n.header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n    height: 113px;\n    min-height: 113px;\n    background-color: #F8F8F8;\n    border-radius: 15px;\n}\n\n.header>div:first-of-type {\n    color: #192949;\n    font-size: 40px;\n    font-weight: 500;\n    padding-left: 23px;\n}\n\n.configureButton {\n    user-select: none;\n    cursor: pointer;\n    background-color: #F32735;\n    border-radius: 0 15px 15px 0;\n    height: 113px;\n    vertical-align: middle;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    width: 133px;\n    min-width: 133px;\n    font-size: 12px;\n    font-weight: 500;\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `dashboard_container__8Kvpf`,
	"header": `dashboard_header__DEVuQ`,
	"configureButton": `dashboard_configureButton__f7D-g`
};
export default ___CSS_LOADER_EXPORT___;
