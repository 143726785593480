import React, { useEffect, useState } from "react";
import styles from "./output.module.css";
import Switch from "../switch/switch";
import Gauge from "../../screens/detail/components/gauge/gauge";
import BatteryGauge from "../battery_gauge/battery_gauge";
import axiosInstance from "../../AxiosInstance";
import { openModal } from "../ajonjolib/toasts/toast/toast";

function VerticalDivider() {
  return (
    <div
      className={
        "d-flex flex-column justify-content-center align-items-center h-100"
      }
    >
      <div className={styles.verticalDivider} />
    </div>
  );
}

function PortsPopup({ top, left, port, kpduData }) {
  return (
    <div
      className={styles.portPopup}
      style={{
        top: top,
        left: left,
      }}
    >
      <div
        style={{
          position: "relative",
          width: "90%",
        }}
      >
        <img
          alt={"kpdu"}
          src={`/kpdus/${kpduData?.model?.image_name}/kpdu_ports.png`}
          width={"100%"}
          height={"auto"}
        />
        <div
          key={port}
          className={`${styles.port}`}
          style={{
            cursor: "pointer",
            top: `${kpduData?.model["port_y_values"][port - 1]}%`,
            left: `${kpduData?.model["port_x_values"][port - 1]}%`,
          }}
        />
      </div>
    </div>
  );
}

export default function Output({
  number,
  className,
  kpdu_id,
  data,
  onClick,
  kpduData,
}) {
  const [status, setStatus] = useState(false);
  const [myData, setMyData] = useState({});
  const [showPorts, setShowPorts] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [ports12v, setPorts12v] = useState(null);

  const power_switch = (e) => {
    axiosInstance.put(`kpdu/${kpdu_id}/output/`, {
      number: number,
      kpdu_id: kpdu_id,
      status: e,
    });
    // .then((response) => {
    //   setStatus(response.data === "true");
    // });
  };

  const reset_switch = () => {
    axiosInstance.put(`kpdu/${kpdu_id}/output_reset/`, {
      number: number,
      kpdu_id: kpdu_id,
    });
    // .then((response) => {
    //   setStatus(!(response?.status === 200));
    // });
  };

  useEffect(() => {
    setStatus(data["status"][number - 1]);
    setMyData({
      voltage: data["voltages"][number - 1],
      current: data["currents"][number - 1],
      power: data["powers"][number - 1],
    });
  }, [data, number]);

  useEffect(() => {
    setPorts12v(kpduData?.ports_12v);
  }, [kpduData]);

  return (
    <div className={styles.outerContainer}>
      {showPorts && (
        <PortsPopup
          top={mousePosition.y}
          left={mousePosition.x}
          port={number}
          kpduData={kpduData}
        />
      )}
      <div
        style={{
          width: "30px",
          height: "50px",
          backgroundColor: "#FFF",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          openModal("output_settings", { data: kpduData, number: number });
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#284480",
            borderBottom: "1px solid #284480",
            marginBottom: "5px",
          }}
        >
          {ports12v?.includes(number) ? "12V" : "24V"}
        </div>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.47 7.24921L15.4637 6.90852C15.3502 6.5489 15.1987 6.2082 15.0284 5.86751L16.2019 4.22082C16.3722 3.97476 16.3533 3.63407 16.1451 3.42587L14.5363 1.81703C14.3281 1.60883 13.9874 1.57098 13.7413 1.76025L12.0946 2.93375C11.7729 2.76341 11.4132 2.61199 11.0536 2.49842L10.7319 0.511041C10.6751 0.208202 10.429 0 10.1262 0H7.85489C7.55205 0 7.30599 0.208202 7.24921 0.511041L6.90852 2.51735C6.5489 2.63091 6.2082 2.78233 5.86751 2.95268L4.22082 1.77918C3.97476 1.60883 3.63407 1.62776 3.42587 1.83596L1.81703 3.4448C1.60883 3.653 1.57098 3.99369 1.76025 4.23975L2.93375 5.88644C2.76341 6.2082 2.61199 6.56782 2.49842 6.92744L0.511041 7.24921C0.208202 7.30599 0 7.55205 0 7.85489V10.1262C0 10.429 0.208202 10.6751 0.511041 10.7319L2.51735 11.0726C2.63091 11.4322 2.78233 11.7729 2.95268 12.1136L1.77918 13.7603C1.60883 14.0063 1.62776 14.347 1.83596 14.5552L3.4448 16.164C3.653 16.3722 3.99369 16.4101 4.23975 16.2208L5.88644 15.0473C6.2082 15.2177 6.56782 15.3691 6.92744 15.4826L7.26814 17.489C7.32492 17.7918 7.57098 18 7.87382 18H10.1451C10.4479 18 10.694 17.7918 10.7508 17.489L11.0726 15.4637C11.4322 15.3502 11.7729 15.1987 12.1136 15.0284L13.7603 16.2019C14.0063 16.3722 14.347 16.3533 14.5552 16.1451L16.164 14.5363C16.3722 14.3281 16.4101 13.9874 16.2208 13.7413L15.0473 12.0946C15.2177 11.7729 15.3691 11.4132 15.4826 11.0536L17.489 10.7129C17.7918 10.6562 18 10.4101 18 10.1073V7.85489C17.9811 7.55205 17.7729 7.30599 17.47 7.24921ZM8.99054 12.2271C7.19243 12.2271 5.75394 10.7697 5.75394 8.99054C5.75394 7.19243 7.21136 5.75394 8.99054 5.75394C10.7697 5.75394 12.2271 7.19243 12.2271 8.99054C12.2271 10.7886 10.7886 12.2271 8.99054 12.2271Z"
            fill="#797D86"
          />
        </svg>
      </div>
      <div className={`${styles.container} ${className}`} onClick={onClick}>
        <div
          className={`${styles.status} ${
            status === false ? styles.statusOff : ""
          }`}
          onMouseEnter={(event) => {
            setShowPorts(true);
            setMousePosition({ x: event.clientX + 20, y: event.clientY });
          }}
          onMouseLeave={(event) => {
            setShowPorts(false);
            setMousePosition({ x: event.clientX, y: event.clientY });
          }}
        >
          <div style={{ height: "38px" }} />
          <div>
            <div style={{ fontSize: "40px", lineHeight: "36px" }}>{number}</div>
            <div
              style={{
                fontSize: "16px",
                color: status === false ? "#818181" : "#649E51",
                transition: "color 0.3s ease",
              }}
            >
              OUT
            </div>
          </div>
          <div
            onClick={(event) => {
              event.stopPropagation();
              reset_switch();
            }}
            style={{
              height: "38px",
            }}
          >
            {status === true && <div className={styles.resetButton}>RESET</div>}
          </div>
        </div>

        <div className={"d-flex w-100"}>
          <Gauge
            min={0}
            max={24}
            value={myData.voltage}
            unit={"V"}
            label={"Voltaje"}
          />
          <VerticalDivider />
          <Gauge
            min={0}
            max={24}
            value={myData.current}
            unit={"A"}
            label={"Corriente"}
          />
          <VerticalDivider />
          <BatteryGauge name={"Potencia"} unit={"W"} value={myData.power} />
          <VerticalDivider />
          <Switch value={status} id={number} onChange={power_switch} />
        </div>
      </div>
    </div>
  );
}
