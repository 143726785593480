import { useEffect, useState } from "react";

import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import axiosInstance from "../../../../AxiosInstance";

const ResetAllModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [kpduId, setKpduId] = useState(null);

  useEffect(() => {
    eventBus.subscribe("reset_all_open", (rec_data) => {
      console.log("reset_all_open", rec_data);
      setIsOpen(true);
      setKpduId(rec_data.kpduId);
    });
    eventBus.subscribe("reset_all_close", () => {
      setIsOpen(false);
    });
  }, []);

  const onClick = async () => {
    if (kpduId && !loading) {
      setLoading(true);
      try {
        await axiosInstance.put(`/kpdu/${kpduId}/reset_all/`);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setIsOpen(false);
      }
    }
  };

  return (
    <div>
      {isOpen && (
        <Modal close={() => setIsOpen(false)}>
          <div className={"d-flex flex-column justify-content-center my-3"}>
            <h3 className={"text-dark"}>Reset All</h3>
            <p className={"fs-6"}>
              ¿Estás seguro de que quieres realizar esta acción?
            </p>
            <div className={"d-flex justify-content-center gap-4"}>
              <button
                className={"btn text-light"}
                style={{
                  backgroundColor: loading ? "#d4d4d4" : "#66CC66",
                  cursor: loading ? "not-allowed" : "pointer",
                }}
                onClick={onClick}
              >
                Sí
              </button>
              <button
                className={"btn btn-danger"}
                onClick={() => setIsOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ResetAllModal;
