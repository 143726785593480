import React from "react";

export default function Label({children}) {
    return (
        <div style={{
            color: "#4B5563",
            fontWeight: "500",
            paddingBottom: "0.2rem",
        }}>
            {children}
        </div>
    )
}