// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radioButtons_container__CVNdA {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 30px 10px 30px;
  padding: 10px 0;
  position: relative;
  align-items: center;
  border-radius: 6px;
}

.radioButtons_radioLabel__O2X\\+0 {
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  color: #6a86c8;
}

.radioButtons_radioLabel__O2X\\+0 input[type="radio"] {
  margin-right: 10px;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #49546e;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.radioButtons_radioLabel__O2X\\+0 input[type="radio"]:checked {
  background-color: #88d36e;
}

.radioButtons_radioLabel__O2X\\+0 input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 3px 0px;
}

.radioButtons_radioLabel__O2X\\+0 input[type="radio"]:hover {
  background-color: #6a86c8;
}

.radioButtons_radioLabel__O2X\\+0 input[type="radio"]:disabled {
  background-color: #a9a9a9;
  cursor: not-allowed;
}

.radioButtons_radioLabel__O2X\\+0 input[type="radio"]:disabled::after {
  background-color: #888888;
}
`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/inputs/horizontal_switch/radioButtons.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,+CAA+C;AACjD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  margin: 0 30px 10px 30px;\n  padding: 10px 0;\n  position: relative;\n  align-items: center;\n  border-radius: 6px;\n}\n\n.radioLabel {\n  display: flex;\n  align-items: center;\n  font-weight: 600;\n  cursor: pointer;\n  color: #6a86c8;\n}\n\n.radioLabel input[type=\"radio\"] {\n  margin-right: 10px;\n  appearance: none;\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n  background-color: #49546e;\n  position: relative;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.radioLabel input[type=\"radio\"]:checked {\n  background-color: #88d36e;\n}\n\n.radioLabel input[type=\"radio\"]:checked::after {\n  content: \"\";\n  position: absolute;\n  top: 6px;\n  left: 6px;\n  width: 13px;\n  height: 13px;\n  border-radius: 50%;\n  background-color: white;\n  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 3px 0px;\n}\n\n.radioLabel input[type=\"radio\"]:hover {\n  background-color: #6a86c8;\n}\n\n.radioLabel input[type=\"radio\"]:disabled {\n  background-color: #a9a9a9;\n  cursor: not-allowed;\n}\n\n.radioLabel input[type=\"radio\"]:disabled::after {\n  background-color: #888888;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `radioButtons_container__CVNdA`,
	"radioLabel": `radioButtons_radioLabel__O2X+0`
};
export default ___CSS_LOADER_EXPORT___;
