import React, { useEffect, useState } from "react";
import {
  subscribeModal,
  toast,
  ToastTypes,
} from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import ModalFooter from "../../../../components/ajonjolib/modal/modal_footer/modal_footer";
import ModalHeader from "../../../../components/ajonjolib/modal/modal_header/modal_header";
import ModalBody from "../../../../components/ajonjolib/modal/modal_body/modal_body";
import styles from "./configuration_modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../../AxiosInstance";
import { Select } from "../../../../components/ajonjolib/inputs/ajonjolinput";

function KPDUDashboardConfig({ index, items, setItems, kpdus, setDeleted }) {
  console.log(items[index].kpdu_id);
  const port_count = 10;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={"d-flex align-items-center"}>
          <Select
            options={kpdus.map((x) => {
              return { name: x.name, value: x.id };
            })}
            value={items[index].kpdu_id}
            onChange={(x) => {
              let copy = items;
              copy[index]["kpdu_id"] = x[0];
              setItems([...copy]);
            }}
          />
          <div className={"ms-2"}>Seleccionar salidas a mostrar</div>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faTrash}
            color={"#BABABA"}
            size={"xl"}
            role={"button"}
            onClick={() => {
              if (items[index]?.id) {
                setDeleted((prev) => [...prev, items[index].id]);
              }
              let copy = items;
              copy.splice(index, 1);
              setItems([...copy]);
            }}
          />
        </div>
      </div>
      <div className={styles.body}>
        {Array.from({ length: port_count }, (_, index) => index + 1).map(
          (port) => {
            return (
              <div className={styles.outputContainer}>
                <div
                  className={`${styles.outputToggle} ${
                    items[index]["outputs"][port - 1] && styles.active
                  }`}
                  onClick={() => {
                    items[index]["outputs"][port - 1] =
                      !items[index]["outputs"][port - 1];
                    setItems([...items]);
                  }}
                >
                  <div>OUT {port}</div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

export default function ConfigurationModal() {
  const [isOpen, setIsOpen] = useState(false);
  const modalName = "configuration_modal";
  const [items, setItems] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [kpdus, setKpdus] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    subscribeModal(modalName, setIsOpen, (rec_data) => {
      setKpdus(rec_data);
    });

    axiosInstance.get("dashboard/").then((response) => {
      if (response?.status !== 200) {
        return;
      }
      setItems(response.data.results);
    });
  }, [isOpen]);

  const submit = () => {
    if (!loading) {
      setLoading(true);
      axiosInstance
        .post("dashboard/", { items, deleted })
        .then((response) => {
          setIsOpen(false);
          setLoading(false);
          if (response?.status === 200) {
            window.location.reload();
          } else {
            toast("Error al guardar", ToastTypes.ERROR);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const addItem = () => {
    setItems([
      ...items,
      {
        kpdu_id: 0,
        output_1: false,
        output_2: false,
        output_3: false,
        output_4: false,
        output_5: false,
        output_6: false,
        output_7: false,
        output_8: false,
        output_9: false,
        output_10: false,
      },
    ]);
  };

  return (
    <div>
      {isOpen && (
        <Modal close={() => setIsOpen(false)}>
          <ModalHeader title={"Dashboard"} subtitle={"Configuración"} />
          <ModalBody>
            <div>
              <div style={{ overflowY: "scroll", maxHeight: "400px" }}>
                {items.map((item, index) => (
                  <KPDUDashboardConfig
                    index={index}
                    items={items}
                    setItems={setItems}
                    kpdus={kpdus}
                    setDeleted={setDeleted}
                  />
                ))}
              </div>
              <div
                className={
                  "d-flex justify-content-center align-items-center mt-3"
                }
              >
                <div
                  className={styles.addButton}
                  onClick={() => {
                    addItem();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className={"pe-2"} />
                  <div>Agregar</div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter submit={submit} submitLoading={loading} />
        </Modal>
      )}
    </div>
  );
}
