import React from "react";
import styles from './modal.module.css';

export default function Modal({children, close}) {
    return (
        <div className={styles.container}>
            <div className={styles.backdrop} onClick={close}/>
            <div className={styles.modal}>
                <div style={{
                    color: '#8B8B8B',
                    fontWeight: '400',
                    fontSize: '14px',
                }}>
                    {children}
                </div>
            </div>
        </div>
    );
}