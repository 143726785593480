import React, { useEffect, useState } from "react";

import styles from "../../detail.module.css";
import LineGraph from "../line_graph/line_graph";
import axiosInstance from "../../../../AxiosInstance";

export default function OutputGraphicGroup({ selectedOutput, kpdu_id }) {
  const [data, setData] = useState([]);
  const [dataHour, setDataHour] = useState([]);
  const [graphNames, setGraphNames] = useState({});
  const [locked, setLocked] = useState(false);

  const getData = () => {
    if (locked) return;
    setLocked(true);
    axiosInstance.get(`kpdu/${kpdu_id}/output_data/`).then((response) => {
      setLocked(false);
      if (response?.status !== 200) {
        return;
      }
      let data = response.data.map((item) => {
        item.avg_power = (item.avg_power / 1000).toFixed(1);
        item.avg_voltage = (item.avg_voltage / 1000).toFixed(1);
        item.avg_current = (item.avg_current / 1000).toFixed(1);
        return item;
      });
      setData(data.reverse());
    });
  };

  useEffect(() => {
    axiosInstance
      .get(`kpdu/${kpdu_id}/output_data_hour/?number=${selectedOutput}`)
      .then((response) => {
        if (response?.status !== 200) {
          return;
        }
        setDataHour(response.data);
      });
  }, [selectedOutput]);

  useEffect(() => {
    if (selectedOutput === -1) {
      setGraphNames({
        voltage: "input_voltage",
        current: "input_current",
        power: "input_power",
      });
    } else {
      setGraphNames({
        voltage: `voltages[${selectedOutput - 1}]`,
        current: `currents[${selectedOutput - 1}]`,
        power: `powers[${selectedOutput - 1}]`,
      });
    }

    getData();

    const interval = setInterval(() => {
      getData();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedOutput, locked]);

  return (
    <div
      style={{
        backgroundColor: "#F8F8F8",
        borderRadius: "15px",
        padding: "12px",
        boxShadow:
          "rgba(0, 0, 0, 8%) 0 8px 13px -2px, rgba(27, 41, 74, 0.29) 0 17px 4px -14px",
      }}
    >
      {/* GENERAL */}
      <LineGraph
        unit={"KW/h"}
        min={0}
        max={100}
        color={"#8B41C5"}
        secondaryColor={"#8B41C5"}
        entries={dataHour}
        valueName={`avg_power`}
        header={[
          <div
            className={
              "d-flex justify-content-between align-items-center px-3 py-3"
            }
          >
            <div className={"d-flex align-items-center"}>
              <img
                alt={"thunder"}
                src={"/icons/graphs/general.png"}
                width={"19px"}
                height={"24px"}
                className={"me-2"}
              />
              <div
                className={"d-flex align-items-center"}
                style={{
                  color: "#19294999",
                  fontSize: "16px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                <div className={"pe-2"}>Consumo Electrico</div>
                {selectedOutput !== -1 ? (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    OUT {selectedOutput}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    IN
                  </div>
                )}
              </div>
            </div>
            <div className={"d-flex"}>
              <div className={styles.frequency}>
                Año <div>2024</div>
              </div>
              <div className={styles.frequency}>
                Mes <div>Febrero</div>
              </div>
              <div className={styles.frequency}>Semana</div>
            </div>
          </div>,
        ]}
      />
      <div className={"pb-3"} />
      {/* VOLTAJE */}
      <LineGraph
        unit={"V"}
        min={0}
        max={24}
        entries={data}
        color={"#E71B29"}
        valueName={graphNames?.voltage}
        secondaryColor={"#E71B29"}
        header={[
          <div
            className={
              "d-flex justify-content-between align-items-center px-3 py-3"
            }
          >
            <div className={"d-flex align-items-center"}>
              <img
                alt={"thunder"}
                src={"/icons/graphs/voltage.png"}
                width={"16px"}
                height={"100%"}
                className={"me-2"}
              />
              <div
                className={"d-flex align-items-center"}
                style={{
                  color: "#19294999",
                  fontSize: "16px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                <div className={"pe-2"}>Voltaje</div>
                {selectedOutput !== -1 ? (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    OUT {selectedOutput}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    IN
                  </div>
                )}
              </div>
            </div>
            <div className={"d-flex"}>
              <div className={styles.frequency}>
                Año <div>2024</div>
              </div>
              <div className={styles.frequency}>
                Mes <div>Febrero</div>
              </div>
              <div className={styles.frequency}>Semana</div>
            </div>
          </div>,
        ]}
      />
      <div className={"pb-3"} />
      {/* CORRIENTE */}
      <LineGraph
        unit={"A"}
        min={0}
        max={20}
        entries={data}
        color={"#D08E2A"}
        valueName={graphNames?.current}
        secondaryColor={"#D08E2A"}
        header={[
          <div
            className={
              "d-flex justify-content-between align-items-center px-3 py-3"
            }
          >
            <div className={"d-flex align-items-center"}>
              <img
                alt={"thunder"}
                src={"/icons/graphs/current.png"}
                width={"15px"}
                height={"22px"}
                className={"me-2"}
              />
              <div
                className={"d-flex align-items-center"}
                style={{
                  color: "#19294999",
                  fontSize: "16px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                <div className={"pe-2"}>Corriente</div>
                {selectedOutput !== -1 ? (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    OUT {selectedOutput}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    IN
                  </div>
                )}
              </div>
            </div>
            <div className={"d-flex"}>
              <div className={styles.frequency}>
                Año <div>2024</div>
              </div>
              <div className={styles.frequency}>
                Mes <div>Febrero</div>
              </div>
              <div className={styles.frequency}>Semana</div>
            </div>
          </div>,
        ]}
      />
      <div className={"pb-3"} />
      {/* CONSUMO */}
      <LineGraph
        unit={"W"}
        min={0}
        max={480}
        entries={data}
        color={"#375395"}
        valueName={graphNames?.power}
        secondaryColor={"#375395"}
        header={[
          <div
            className={
              "d-flex justify-content-between align-items-center px-3 py-3"
            }
          >
            <div className={"d-flex align-items-center"}>
              <img
                alt={"thunder"}
                src={"/icons/graphs/power.png"}
                width={"13px"}
                height={"100%"}
                className={"me-2"}
              />
              <div
                className={"d-flex align-items-center"}
                style={{
                  color: "#19294999",
                  fontSize: "16px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                <div className={"pe-2"}>Potencia</div>
                {selectedOutput !== -1 ? (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    OUT {selectedOutput}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    IN
                  </div>
                )}
              </div>
            </div>
            <div className={"d-flex"}>
              <div className={styles.frequency}>
                Año <div>2024</div>
              </div>
              <div className={styles.frequency}>
                Mes <div>Febrero</div>
              </div>
              <div className={styles.frequency}>Semana</div>
            </div>
          </div>,
        ]}
      />
    </div>
  );
}
