// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --sidebar-margin: 236px;
  --sidebar-width: 236px;
}

.App {
  text-align: center;
  height: 100%;
  background-color: rgb(238, 238, 238);
}

html,
body,
#root {
  height: 100%;
  overflow-y: hidden;
  background-color: rgb(238, 238, 238);
  font-family: Mulish, sans-serif;
}

iframe {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,oCAAoC;AACtC;;AAEA;;;EAGE,YAAY;EACZ,kBAAkB;EAClB,oCAAoC;EACpC,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf","sourcesContent":[":root {\n  --sidebar-margin: 236px;\n  --sidebar-width: 236px;\n}\n\n.App {\n  text-align: center;\n  height: 100%;\n  background-color: rgb(238, 238, 238);\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  overflow-y: hidden;\n  background-color: rgb(238, 238, 238);\n  font-family: Mulish, sans-serif;\n}\n\niframe {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
