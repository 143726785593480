import React from "react";

export default function StatusPill({value}) {
    return (
        <div
            style={{
                backgroundColor: value === true ? '#88D36ECC' : '#A9A9A9CC',
                color: value === true ? '#2A880A' : '#808080',
                fontWeight: 500,
                textAlign: 'center',
                borderRadius: '12px',
                userSelect: "none"
            }}
        >
            {value === true ? 'Activo' : 'Inactivo'}
        </div>
    )
}