// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kpdus_container__YfuhG {

}

.kpdus_kpduContainer__eF6mg {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    min-height: 174px;
    min-width: 174px;
    max-height: 174px;
    max-width: 174px;
    border-radius: 17px;
    color: #E72D39;
    background-color: #FFFFFF;
    box-shadow: 0 8px 13px -2px rgba(0, 0, 0, 8%);
    margin: 25px 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.kpdus_kpduName__I6F7n {
    font-weight: bolder;
    font-size: 30px;
}

.kpdus_kpduDetails__\\+LE4E {
    font-size: 10px;
    white-space: nowrap;
    text-align: left;
    color: #000000;
    width: 90%;
}

.kpdus_selected__zsAkv .kpdus_kpduDetails__\\+LE4E {
    color: #FFFFFF !important;
}

.kpdus_divider__Xc7f5 {
    background-color: #E72D39;
    width: 80%;
    height: 1px;
    margin: 10px 0;
}

.kpdus_selected__zsAkv .kpdus_divider__Xc7f5 {
    background-color: #FFFFFF !important;
}

.kpdus_selected__zsAkv {
    background-color: #E72D39 !important;
    color: #FFFFFF !important;
    box-shadow: inset 0 4px 10px 1px #7E000C !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/kpdus/kpdus.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,6CAA6C;IAC7C,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,UAAU;AACd;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,mDAAmD;AACvD","sourcesContent":[".container {\n\n}\n\n.kpduContainer {\n    cursor: pointer;\n    user-select: none;\n    min-height: 174px;\n    min-width: 174px;\n    max-height: 174px;\n    max-width: 174px;\n    border-radius: 17px;\n    color: #E72D39;\n    background-color: #FFFFFF;\n    box-shadow: 0 8px 13px -2px rgba(0, 0, 0, 8%);\n    margin: 25px 25px;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n\n.kpduName {\n    font-weight: bolder;\n    font-size: 30px;\n}\n\n.kpduDetails {\n    font-size: 10px;\n    white-space: nowrap;\n    text-align: left;\n    color: #000000;\n    width: 90%;\n}\n\n.selected .kpduDetails {\n    color: #FFFFFF !important;\n}\n\n.divider {\n    background-color: #E72D39;\n    width: 80%;\n    height: 1px;\n    margin: 10px 0;\n}\n\n.selected .divider {\n    background-color: #FFFFFF !important;\n}\n\n.selected {\n    background-color: #E72D39 !important;\n    color: #FFFFFF !important;\n    box-shadow: inset 0 4px 10px 1px #7E000C !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `kpdus_container__YfuhG`,
	"kpduContainer": `kpdus_kpduContainer__eF6mg`,
	"kpduName": `kpdus_kpduName__I6F7n`,
	"kpduDetails": `kpdus_kpduDetails__+LE4E`,
	"selected": `kpdus_selected__zsAkv`,
	"divider": `kpdus_divider__Xc7f5`
};
export default ___CSS_LOADER_EXPORT___;
