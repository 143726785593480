// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line_graph_container__2hV4i {
    background-color: #FFFFFF;
    width: 100%;
    min-height: 300px;
    border-radius: 15px;
    border: 1px solid #F2F2F2;
}`, "",{"version":3,"sources":["webpack://./src/screens/detail/components/line_graph/line_graph.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".container {\n    background-color: #FFFFFF;\n    width: 100%;\n    min-height: 300px;\n    border-radius: 15px;\n    border: 1px solid #F2F2F2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `line_graph_container__2hV4i`
};
export default ___CSS_LOADER_EXPORT___;
