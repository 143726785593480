// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail_container__HrvOP {
    margin-left: calc(var(--sidebar-width) + 20px);
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 260px);
}

.detail_outputContainer__A6NjQ {
    min-width: 760px;
}

.detail_outputContainer__A6NjQ,
.detail_detailContainer__ldDLl {
    overflow-y: scroll;
    flex-basis: 47%;
}

.detail_detailContainer__ldDLl {
    background-color: #ECF0F8;
    padding: 24px;
    border-radius: 15px;
    font-family: Mulish, sans-serif;
    height: calc(100% - 30px);
    box-shadow: 0 3px 18px 1px #00206B33, -1px 2px 3px -2px #00206B40;
    margin-right: 20px;
}

.detail_frequency__My76J {
    border-radius: 13px;
    background-color: #EEEEEE;
    color: #375395;
    display: flex;
    padding: 5px 10px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    margin-left: 5px;
}

.detail_frequency__My76J>div {
    padding-left: 5px;
    color: #37539580;
}
`, "",{"version":3,"sources":["webpack://./src/screens/detail/detail.module.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,aAAa;IACb,8BAA8B;IAC9B,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,+BAA+B;IAC/B,yBAAyB;IACzB,iEAAiE;IACjE,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".container {\n    margin-left: calc(var(--sidebar-width) + 20px);\n    display: flex;\n    justify-content: space-between;\n    height: calc(100vh - 260px);\n}\n\n.outputContainer {\n    min-width: 760px;\n}\n\n.outputContainer,\n.detailContainer {\n    overflow-y: scroll;\n    flex-basis: 47%;\n}\n\n.detailContainer {\n    background-color: #ECF0F8;\n    padding: 24px;\n    border-radius: 15px;\n    font-family: Mulish, sans-serif;\n    height: calc(100% - 30px);\n    box-shadow: 0 3px 18px 1px #00206B33, -1px 2px 3px -2px #00206B40;\n    margin-right: 20px;\n}\n\n.frequency {\n    border-radius: 13px;\n    background-color: #EEEEEE;\n    color: #375395;\n    display: flex;\n    padding: 5px 10px;\n    cursor: pointer;\n    user-select: none;\n    margin-left: 5px;\n}\n\n.frequency>div {\n    padding-left: 5px;\n    color: #37539580;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `detail_container__HrvOP`,
	"outputContainer": `detail_outputContainer__A6NjQ`,
	"detailContainer": `detail_detailContainer__ldDLl`,
	"frequency": `detail_frequency__My76J`
};
export default ___CSS_LOADER_EXPORT___;
