import React, { useState } from "react";
import styles from "./temperature_section.module.css";
import {
  getTemperatureScale,
  numberToLetter,
  temperatureToHex,
  zoneToCoods,
  zoneToName,
} from "../../../../../utils/utils";

function Temperature({ item }) {
  return (
    <div className={"d-flex align-items-center"}>
      <div
        className={styles.temperature}
        style={{ backgroundColor: item.hex }}
      />
      <div>{item.value}°C</div>
    </div>
  );
}

function TemperatureIndicator({
  coords,
  hex,
  zone,
  selectedZone,
  setSelectedZone,
}) {
  return (
    <div
      className={styles.outerIndicator}
      style={{
        top: `calc(${coords.top} - 20px)`,
        left: `calc(${coords.left} - 20px)`,
        background:
          zone === selectedZone
            ? "radial-gradient(#FFF 20%, #FFF4 86%, #FFF0 90%)"
            : "transparent",
      }}
    >
      <div
        className={styles.indicator}
        style={{
          backgroundColor: hex + "66",
        }}
      >
        <div
          className={styles.innerIndicator}
          style={{
            backgroundColor: hex,
          }}
        />
      </div>
    </div>
  );
}

export default function TemperatureSection({ kpduData }) {
  const [selectedZone, setSelectedZone] = useState(-1);

  return (
    <div className={styles.container}>
      <div className={styles.heatmap}>
        <div className={styles.legend}>
          <div
            className={
              "d-flex justify-content-between align-items-start flex-column h-100"
            }
          >
            {getTemperatureScale().map((item, index) => (
              <Temperature key={index} item={item} />
            ))}
          </div>
        </div>
        <div className={styles.map}>
          <img
            src={`/kpdus/${kpduData?.model?.image_name}/kpdu_board.png`}
            alt={"kpdu"}
            height={"350px"}
          />
          {kpduData.temperatures.temperatures.map((temperature, index) => (
            <TemperatureIndicator
              zone={index}
              selectedZone={selectedZone}
              setSelectedZone={setSelectedZone}
              hex={temperatureToHex(temperature)}
              coords={zoneToCoods(index, kpduData?.model?.image_name)}
            />
          ))}
        </div>
      </div>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Zona</th>
              <th>Temperatura</th>
            </tr>
          </thead>
          <tbody>
            {kpduData.temperatures.temperatures.map((temperature, index) => (
              <tr
                onClick={() => {
                  setSelectedZone(index);
                }}
              >
                <td>{numberToLetter(index)}</td>
                <td>{zoneToName(index, kpduData?.model?.image_name)}</td>
                <td>
                  <div
                    className={
                      "d-flex align-items-center justify-content-between"
                    }
                  >
                    <div className={"d-flex align-items-center"}>
                      <div>
                        {parseFloat(temperature) > -30 &&
                        parseFloat(temperature) !== 999
                          ? parseFloat(temperature).toFixed(1)
                          : null}
                      </div>
                      <div className={"ps-2"} style={{ color: "#B4C3E4" }}>
                        °C
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: temperatureToHex(temperature),
                        width: "38px",
                        height: "24px",
                        borderRadius: "8px",
                        marginLeft: "10px",
                        marginRight: "20px",
                      }}
                    ></div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
