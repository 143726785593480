import React from "react";
import styles from './custom_tooltip.module.css';

export default function CustomTooltip({ active, payload, label, frequency, unit, color }) {
    return (
        <div className={styles.container} style={{
            backgroundColor: color,
        }}>
            {payload &&
                <div className={'d-flex justify-content-center align-items-baseline'}>
                    <div style={{fontSize: '20px'}} className={'pe-1'}>{Math.abs(payload[0]?.value).toFixed(1)}</div>
                    <div style={{fontSize: '9px'}}>{unit}</div>
                </div>
            }
        </div>
    );
}