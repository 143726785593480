import React, {useEffect} from "react";
import styles from './gauge.module.css';

export default function Gauge({value, label, min, max, unit}) {
    const calculatePercentage = () => {
        if (min >= max) {
            console.error('Error: Min must be less than Max.');
            return null;
        }

        const clampedValue = Math.min(Math.max(value, min), max);
        const percentage = ((clampedValue - min) / (max - min)) * 100;

        return percentage ? percentage : 0;
    };

    function getDegree() {
        const minDegrees = 0;
        const maxDegrees = 180;
        // Ensure the percentage is within the valid range [0, 100]
        const validPercentage = calculatePercentage();

        // Calculate the degrees within the specified range
        const degreesRange = maxDegrees - minDegrees;
        const degrees = (validPercentage / 100) * degreesRange + minDegrees;

        return degrees ? degrees : 0;
    }

    return (
        <div className={styles.halfContainer}>
            <div className={styles.container}>
                <div className={styles.gaugeContainer} style={{
                    background: `conic-gradient(
                        #284480 0deg ${getDegree()}deg,
                        #D9D9D9 ${getDegree()}deg 180deg,
                        transparent 180deg 360deg
                    )`,
                }}>
                    <div className={styles.gauge}>
                        <div className={styles.gaugeValue}>
                            <div>{value ? Math.abs(value)?.toFixed(1) : (0).toFixed(1)}</div>
                            <div style={{fontSize: '15px'}}>{unit}</div>
                        </div>
                        <div className={styles.gaugeLabel}>{label}</div>
                    </div>
                </div>
                <div className={styles.leftBall}/>
                <div className={styles.rightBall} style={{
                    background: value !== max ? 'conic-gradient(transparent 0deg 90deg, #D9D9D9 90deg 270deg, transparent 270deg 360deg)' : 'conic-gradient(transparent 0deg 90deg, #284480 90deg 270deg, transparent 270deg 360deg)',
                }}/>
            </div>
        </div>
    );
}